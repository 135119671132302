@import 'mixins.pcss';

.root {
    display: flex;
    flex: auto;
    flex-direction: column;
    min-height: 100vh;
}

.layout {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-width: 0;
}

.main {
    display: flex;
    flex: auto;
    flex-direction: row;
}

.content {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    padding: 16px 24px;
}

.loader {
    &:after {
        background-color: var(--color-bg) !important;
    }

    &Large [class='SBA-loader-spinner-with-label'] {
        min-height: 100%;
    }
}
